import React from 'react';
import './Hero.css';
import Header from '../Header/Header';
import hero_image from "../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import Heart from "../../assets/heart.png";
import Calories from "../../assets/calories.png";
import { motion } from 'framer-motion';
const hero = () => {
    const transition = {type:'spring',duration:2}

  return (
    <div className='hero'>

        <div className="blur hero-blur"></div>
        <div className='left-h'>
            <Header/>
{/*the best ad*/}
            <div className="the-best-ad">
                <motion.div
                initial={{left:'238px'}}
                whileInView={{left:'8px'}}
                transition={{...transition,type:'tween'}} 
                ></motion.div>
                <span>the best fitness club in the town</span>
            </div>

            {/* hero heading*/}
            <div className="hero-text">
                <div>
                   <span className='strok-text'>Shape</span>
                   <span>Your</span>
                </div>
                <div>
                    <span>Ideal Body</span>
                </div>
                <div>
                    <span>
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime, voluptate!
                    </span>
                </div>
            </div>

            {/*figures*/}
             <div className="figures">
                <div>
                    <span>+140</span>
                    <span>experts coachs</span>
                </div>
                <div>
                    <span>+978</span>
                    <span>member joins</span>
                </div>
                <div>
                    <span>+50</span>
                    <span>fitness programs</span>
                </div>
             </div>

             {/*hero button*/}
                <div className="hero-buttons">
                    <button className="btn">Get Started</button>
                    <button className="btn">Learn More</button>
                </div>
         </div>
         {/*right side*/}
        <div className='right-h'>
            <button className="btn">join Now</button>

            <motion.div 
            initial={{right:'-1rem'}}
            whileInView={{right:'4rem'}}
            transition={{...transition,duration:3}}
            className="heart-rate">
                <img src={Heart} alt="" />
                <span>Heart Rate</span><span>116 bps</span>
            </motion.div>

            {/*hero-image*/}

            <img src={hero_image} className="hero-image" alt='' />
            <motion.img
            initial={{right:'11rem'}}
            whileInView={{right:'20rem'}}
            transition={transition}
            src={hero_image_back} className="hero-image-back" alt='' />
            {/*calories */} 
            <motion.div
            initial={{right:"32rem"}}
            whileInView={{right:'20rem'}}
            transition={{...transition,duration:4}}
            className='calories'>
                <img src={Calories} alt="" />
                <div>
                  <span>Calories Burned</span>
                  <span>220 kcal</span>
                </div>
            </motion.div>


        </div>
    </div>
  )
}

export default hero

