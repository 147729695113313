
import './App.css';
import Footer from './compnents/Footer/Footer';
import Hero from './compnents/herosection/Hero'
import Join from './compnents/Join/Join';
import Plans from './compnents/Plans/Plans';
import Programs from './compnents/Programs/Programs';
import Reasons from './compnents/Reasons/Reasons';
import Testimonials from './compnents/Testimonials/Testimonials';
function App() {
  return (
    <div className="App">
      <Hero />
      <Programs />
      <Reasons />
      <Plans />
      <Testimonials />
      <Join />
      <Footer />
    </div>
  );
}

export default App;

